import React, { useState, useEffect } from "react";
import { auth, onAuthStateChanged } from "./firebase";
import Login from "./components/Login";
import Report from "./components/Report";
import "./App.css";
import moment from "moment-timezone";
import { isMobileDevice } from "./utils";
import { inject } from '@vercel/analytics';

// Inject Vercel Analytics
inject();

const App = () => {
  const [activity, setActivity] = useState("hike");
  const [place, setPlace] = useState("Jackson, WY");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (isMobileDevice()) {
      setIsMobile(true);
      setDate(moment().format("YYYY-MM-DD"));
    } else {
      setDate(moment().format("YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleDateChange = (e) => {
    const selectedDate = moment(e.target.value).format("YYYY-MM-DD");
    setDate(selectedDate);

    if (isMobile) {
      e.target.blur();
    }
  };

  if (!user) {
    return <Login />;
  }

  return (
    <div className="app-container">
      <div className="select-container-wrapper">
        <div className="radio-groups">
          <div className="radio-group">
            <div className="radio-option">
              <input
                type="radio"
                id="hike"
                name="activity"
                value="hike"
                checked={activity === "hike"}
                onChange={(e) => setActivity(e.target.value)}
                disabled={loading}
              />
              <label htmlFor="hike">Hike</label>
            </div>
            <div className="radio-option">
              <input
                type="radio"
                id="backcountry-ski"
                name="activity"
                value="backcountry ski"
                checked={activity === "backcountry ski"}
                onChange={(e) => setActivity(e.target.value)}
                disabled={loading}
              />
              <label htmlFor="backcountry-ski">Backcountry Skiing</label>
            </div>
            <div className="radio-option">
              <input
                type="radio"
                id="mountain-bike"
                name="activity"
                value="mountain bike"
                checked={activity === "mountain bike"}
                onChange={(e) => setActivity(e.target.value)}
                disabled={loading}
              />
              <label htmlFor="mountain-bike">Mountain Biking</label>
            </div>
          </div>
          <div className="radio-group">
            <div className="radio-option">
              <input
                type="radio"
                id="jackson"
                name="place"
                value="Jackson, WY"
                checked={place === "Jackson, WY"}
                onChange={(e) => setPlace(e.target.value)}
                disabled={loading}
              />
              <label htmlFor="jackson">Jackson, WY</label>
            </div>
            <div className="radio-option">
              <input
                type="radio"
                id="slc"
                name="place"
                value="Salt Lake City, UT"
                checked={place === "Salt Lake City, UT"}
                onChange={(e) => setPlace(e.target.value)}
                disabled={loading}
              />
              <label htmlFor="slc">Salt Lake City, UT</label>
            </div>
          </div>
        </div>
        <div className="date-select">
          <input
            type="date"
            value={date}
            onChange={handleDateChange}
            disabled={loading}
          />
        </div>
      </div>
      <Report
        activity={activity}
        place={place}
        date={date}
        setLoading={setLoading}
        loading={loading}
        isMobile={isMobile}  // Pass isMobile to Report component
      />
    </div>
  );
};

export default App;