import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'mapbox-gl/dist/mapbox-gl.css';


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);


root.render(
        <App />
);
